import {apiUrl} from "@/state/helpers";
import httpClient from "@/helpers/httpClient";
import router from "../../router/index";
import Cookies from "js-cookie";
export const state = {
	userInfo: {},
	adminAllUsersInfo: null,
	loading: false,
	chatIdToUnlock: null
};
export const actions = {
	async getUserInfo({commit}) {
		try {
			commit("SET_LOADING", true);
			await httpClient.get(apiUrl + '/api/v1/profile')
				.then((response) => {
					return response.data.data;
				})
				.then((data) => {
					commit("UPDATE_USER_INFO", data);
					commit("SET_LOADING", false);
				})
		} catch (e) {
			console.log(e);
			commit("SET_LOADING", false);
		} finally {
			commit("SET_LOADING", false);
		}
	},

	async updateUserInfo({commit, dispatch}, payload) {
		try{
			commit("SET_LOADING", true);
			await httpClient.post(apiUrl + '/api/v1/profile', payload, {
				headers: {
					"Origin": window.location.origin,
					"Content-Type": "multipart/form-data",
				},
			})
				.then((response) => {
					commit('UPDATE_USER_INFO', response.data.data);
					commit("SET_LOADING", false);
					dispatch('notification/success', 'Information updated', {root: true})
				})
		} catch(e) {
			console.log(e)
			if(e.data.errors?.password?.length) {
				dispatch('notification/error', e.data.errors.password[0], {root: true});
			} else if(e.data.errors?.image?.length) {
				dispatch('notification/error', e.data.errors.image[0], {root: true});
			}
			commit("SET_LOADING", false);
		} finally {
			commit("SET_LOADING", false);
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 3000)
		}
	},

	async updateUserPaymentPlan({commit, dispatch}, payload) {
		try {
			commit('SET_LOADING', true)
			await httpClient.post(apiUrl + '/api/v1/payment', payload)
				.then((response) => {
					if (response.status === 200 && response.data.url) {
						Cookies.set('payment', response.data.payment);
						window.location.href = response.data.url;
					} else if(response.status === 200) {
						Cookies.set('payment', response.data.payment);
						router.push('/complete');
					}
					commit('SET_LOADING', false)
				})
		} catch(e) {
			commit('SET_LOADING', false);
			if(e.data.errors.exp_month || e.data.errors.exp_year || e.data.errors.card_number) {
				dispatch('notification/error', 'Expired date or card number is invalid', {root: true});
			} else if(e.data.errors.cvv) {
				dispatch('notification/error', 'CVV is invalid', {root: true});
			} else {
				await router.push('/error');
			}
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true});
			}, 10000);
		}
	},

	async securePaymentProvider({commit}, payload) {
		try {
			commit('SET_LOADING', true)
			await httpClient.get(apiUrl + `/api/v1${payload}`)
				.then((response) => {
					if(response.status === 200){
						router.push('/complete');
					}
				})
		} catch(e) {
			console.log(e)
			commit('SET_LOADING', false)
			await router.push('/error');
		}
	},

	async getAdminListOfAllUsers({commit}, currentPage) {
		try {
			commit("SET_LOADING", true);
			await httpClient.get(apiUrl + `/api/v1/admin/users/?page=${currentPage}`)
				.then((response) => {
					return response.data;
				})
				.then((data) => {
					commit("UPDATE_ADMIN_ALL_USER_INFO", data);
					commit("SET_LOADING", false);
				})
		} catch (e) {
			console.log(e);
			commit("SET_LOADING", false);
		} finally {
			commit("SET_LOADING", false);
		}
	},

	async deleteUserAsAdmin({commit, dispatch}, payload) {
		try {
			commit("SET_LOADING", true);
			await httpClient.delete(apiUrl + `/api/v1/admin/users/${payload.activeUserId}`)
				.then(() => {
					dispatch('getAdminListOfAllUsers', payload.currentPage)
				})
		} catch (e) {
			if(e.status === 403 && e.data.message) {
				dispatch('notification/error', e.data.message, {root: true});
			}
			commit("SET_LOADING", false);
		} finally {
			commit("SET_LOADING", false);
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 3000)
		}
	},

	async updateUserSubscriptionStatusAsAdmin({commit, dispatch}, payload) {
		try {
			commit("SET_LOADING", true);
			const subscriptions = {
					subscriptions_id: payload.subscriptionId,
					subscriptions_status: payload.id
				}
			await httpClient.patch(apiUrl + `/api/v1/admin/users/${payload.userId}`, subscriptions)
				.then(() => {
					dispatch('getAdminListOfAllUsers', payload.currentPage)
				})
		} catch (e) {
			console.log(e);
			commit("SET_LOADING", false);
		} finally {
			commit("SET_LOADING", false);
		}
	}
};
export const mutations = {
	UPDATE_USER_INFO(state, user) {
		state.userInfo = user;
	},
	SET_LOADING(state, data) {
		state.loading = data
	},
	UPDATE_ADMIN_ALL_USER_INFO(state, users) {
		state.adminAllUsersInfo = users;
	},
};
export const getters = {

};
