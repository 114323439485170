<script>
import axios from "axios";
import {pixelsMethods} from "@/state/helpers";

export default {
	methods: {
		...pixelsMethods,
		getTheme() {
			return localStorage.getItem("user-theme");
		},
		setTheme(theme) {
			localStorage.setItem("user-theme", theme);
			this.userTheme = theme;
			document.documentElement.className = theme;
		},
		getMediaPreference() {
			const hasDarkPreference = window.matchMedia(
				"(prefers-color-scheme: dark)"
			).matches;
			if (hasDarkPreference) {
				return "dark-theme";
			} else {
				return "light-theme";
			}
		},

	},
	mounted() {
		const initUserTheme = this.getTheme() || this.getMediaPreference();
		this.setTheme(initUserTheme);
		this.getPixelEvent()
	},
	created() {
		axios.interceptors.response.use(undefined, function (err) {
			// eslint-disable-next-line no-unused-vars
			return new Promise(function (resolve, reject) {
				if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
					this.$store.dispatch("logOut")
				}
				throw err;
			});
		});
	}
}
</script>

<template>
	<div id="app" class="chat-apps">
		<router-view/>
	</div>
</template>
