const scrollDown = () => {
	// let items = document.querySelectorAll(".mess-list-unst");
	// if(items) {
	// 	let last = items[items.length - 1];
	// 	last.scrollIntoView({block: "end"});
	// }
	let endElement = document.getElementById("mess-end");
	endElement.scrollIntoView();
}

export default scrollDown;