import {apiUrl} from "@/state/helpers";
import axios from "axios";
import Cookies from "js-cookie";

export const actions = {
	async getPixelEvent() {
		try {
			await axios.get(apiUrl + '/api/v1/pixels/config')
				.then((r) => {
					console.log(r)
				})
		} catch (e) {
			console.log(e);
		}
	},
	async sendPixelEvent({commit},event) {
		try {
			await axios.post(apiUrl + `/api/v1/pixels/${event}`,
				{
					net: Cookies.get('net') === 'undefined' ? '' : Cookies.get('net'),
					aff: Cookies.get('aff') === 'undefined' ? '' : Cookies.get('aff'),
					cid: Cookies.get('cid') === 'undefined' ? '' : Cookies.get('cid'),
					sid: Cookies.get('sid') === 'undefined' ? '' : Cookies.get('sid'),
				})
				.then((r) => {
					if(r.status === 200) {
						const iframe = document.createElement('iframe');

						iframe.style.width = '1px';
						iframe.style.height = '1px';
						iframe.style.position = 'absolute';
						iframe.style.opacity = 0;
						iframe.style.border = 'none';

						document.body.appendChild(iframe);

						iframe.addEventListener('load', () => {
							iframe.contentDocument.open();
							iframe.contentDocument.write(r.data[0]);
							iframe.contentDocument.close();
						});

						iframe.src = 'about:blank';
					}
				})
		} catch (e) {
			console.log(e);
		}
	},
	async sendPurchasePixelEvent({commit}, data) {
		try {
			await axios.post(apiUrl + `/api/v1/pixels/${data.event}`,{
				net: Cookies.get('net') === 'undefined' ? '' : Cookies.get('net'),
				aff: Cookies.get('aff') === 'undefined' ? '' : Cookies.get('aff'),
				cid: Cookies.get('cid') === 'undefined' ? '' : Cookies.get('cid'),
				sid: Cookies.get('sid') === 'undefined' ? '' : Cookies.get('sid'),
				paymentUuid: data.paymentUuid
			})
				.then((r) => {
					if(r.status === 200) {
						const iframe = document.createElement('iframe');

						iframe.style.width = '1px';
						iframe.style.height = '1px';
						iframe.style.position = 'absolute';
						iframe.style.opacity = 0;
						iframe.style.border = 'none';

						document.body.appendChild(iframe);

						iframe.addEventListener('load', () => {
							iframe.contentDocument.open();
							iframe.contentDocument.write(r.data[0]);
							iframe.contentDocument.close();
						});

						iframe.src = 'about:blank';
					}
				})
		} catch (e) {
			console.log(e);
		}
	},
}