import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueCardFormat from 'vue-credit-card-validation';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


import router from './router'

// localStorage.getItem("user-theme") === "light-theme" ? import("@/assets/scss/app.scss") : import ("@/assets/scss/app-dark.scss")
import "@/assets/scss/main.scss"
import i18n from './i18n'

import store from '@/state/store'
import { initFirebaseBackend } from './authUtils'

import { configureFakeBackend } from './helpers/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  // setup fake backend
  configureFakeBackend();
}
Vue.mixin({
	methods: {
		scrollToTop() {
			// window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			return window.location.href = '/login'
		},
	},
});
if(process.env.VUE_APP_AUTHDOMAIN !== 'http://localhost:8085') {
	Sentry.init({
		Vue,
		dsn: "https://498effa4a71124016f8c18dff88783de@o392671.ingest.us.sentry.io/4507384980897792",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

Vue.config.productionTip = true

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueCardFormat)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
