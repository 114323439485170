import {apiUrl} from "@/state/helpers"
import httpClient from "@/helpers/httpClient";

export const state = {
	membershipList: []
}

export const mutations = {
	UPDATE_MEMBERSHIP_LIST(state, list) {
		state.membershipList = list;
	}
}

export const actions = {
	async getMembershipList({commit}) {
		try {
			await httpClient.get(apiUrl + '/api/v1/subscriptions')
				.then((response) => {
					commit('UPDATE_MEMBERSHIP_LIST', response.data.subscriptions.data)
				})
		} catch (e) {
			console.log(e);
		}
	},

	async updateMembership({dispatch}, payload) {
		const {id, status} = payload
		try {
			await httpClient.patch(apiUrl + `/api/v1/subscriptions/${id}`, {status})
				.then(() => {
					dispatch('getMembershipList');
				})
		} catch (e) {
			console.log(e);
		}
	}
}

export const getters = {};