import {apiUrl} from "@/state/helpers";
import httpClient from "@/helpers/httpClient";
import scrollDown from "@/helpers/scrollDown";

export const state = {
	loading: false,
	loadingMessages: false,
	loadingRoom: false,
	roomsList: [],
	activeRoomId: null,
	currentRoomMessages: [],
};
export const actions = {
	async getRoomsList({commit}) {
		try {
			commit('SET_LOADING', true);
			await httpClient.get(apiUrl + '/api/v1/chat')
				.then((response) => {
					commit('SET_ROOMS_LIST', response.data.data);
				})
				.then(() => {
					commit('SET_LOADING', false)
				})
		} catch(e){
			console.log(e);
			commit('SET_LOADING', false);
		}
	},

	async createChatRoom({commit, dispatch}, payload) {
		try{
			commit('SET_LOADING', true);
			await httpClient.post(apiUrl + '/api/v1/chat', payload)
				.then((response) => {
					commit('SET_ACTIVE_ROOM_ID', response.data.id);
					dispatch('getSingleRoomMessages', response.data.id)
				})
				.then(() => {
					dispatch('getRoomsList');
					window.location.href = '/chat';
					commit('SET_LOADING', false);
				})

			// const res = await httpClient.post(apiUrl + '/api/v1/chat', payload)
			// if (res.status === 200) {
			// 	await dispatch('getRoomsList');
			// 	await dispatch('getSingleRoomMessages', res.data.id)
			// 	await commit('SET_ACTIVE_ROOM_ID', res.data.id);
			// }
		} catch(e) {
			commit('SET_LOADING', false);
		} finally {
			commit('SET_LOADING', false);
		}
	},

	async updateChatRoom({commit, dispatch}, payload) {
		try{
			commit('SET_LOADING', true);
			await httpClient.post(apiUrl + `/api/v1/chat/update/${state.activeRoomId}`, payload, {
				headers: {
					"Origin": window.location.origin,
					"Content-Type": "multipart/form-data",
				},
			})
				.then((response) => {
					dispatch('getSingleRoomMessages', response.data.id)
				})
				.then(() => {
					dispatch('getRoomsList');
					commit('SET_LOADING', false);
				})
		} catch(e) {
			console.log(e);
			if(e.data.errors?.image?.length) {
				dispatch('notification/error', e.data.errors.image[0], {root: true});
			} else {
				dispatch('notification/error', 'Chat update failed', {root: true});
			}
			commit('SET_LOADING', false);
		} finally {
			commit("SET_LOADING", false);
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 3000)
		}
	},

	async deleteChatRoom({commit, dispatch}, payload) {
		try{
			commit('SET_LOADING', true);
			await httpClient.delete(apiUrl + `/api/v1/chat/${payload}`)
				.then(() => {
					dispatch('getRoomsList');
					commit('SET_LOADING', false);
				})
		} catch(e) {
			console.log(e);
			commit('SET_LOADING', false);
		}
	},

	async getSingleRoomMessages({commit},roomId) {
		try {
			commit('SET_MESSAGE_LIST', []);
			commit('SET_ROOM_LOADING', true);
			await httpClient.get(apiUrl + `/api/v1/chat/${roomId}`)
				.then((response) =>  {
					commit('SET_ACTIVE_ROOM_ID', roomId);
					commit('SET_MESSAGE_LIST', response.data.data.messages);
					commit('SET_ROOM_LOADING', false);
				})
				.then(() => {
					scrollDown();
				})
		} catch(e) {
			commit('SET_ROOM_LOADING', false);
		}
	},

	async sendMessage({commit}, payload) {
		const {chatId, message} = payload;
		const setMessage = {
			chatId: chatId,
			message: message,
			type: 0
		}
		await commit('UPDATE_ROOM_MESSAGES', setMessage);
		setTimeout(() => {
			scrollDown();
		}, 100)
		try {
			commit('SET_MESSAGES_LOADING', true);
			await httpClient.post(apiUrl + `/api/v1/messages/${chatId}`, setMessage)
				.then((response) => {
					if(response.status === 200) {
						commit('UPDATE_ROOM_MESSAGES', response.data.answer);
					}
					commit('SET_MESSAGES_LOADING', false);
					if(document.getElementById("profile-show")?.style) {
						document.getElementById("profile-show").style.display = "none";
					}
				})
				.then(() => {
					scrollDown();
				})
		} catch(e) {
			console.log(e);
			commit('SET_MESSAGES_LOADING', false);
		}
	}
};
export const mutations = {
	UPDATE_ROOM_MESSAGES(state, payload) {
		return state.currentRoomMessages.push(payload)
	},
	SET_MESSAGE_LIST(state, payload) {
		return state.currentRoomMessages = payload;
	},
	SET_ACTIVE_ROOM_ID(state, payload) {
		return state.activeRoomId = payload;
	},
	SET_ROOMS_LIST(state, payload){
		return state.roomsList = payload;
	},
	SET_MESSAGES_LOADING(state, payload) {
		state.loadingMessages = payload;
	},
	SET_ROOM_LOADING(state, payload) {
		state.loadingRoom = payload;
	},
	SET_LOADING(state, payload){
		state.loading = payload;
	}
};
export const getters = {};