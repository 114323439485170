import store from '@/state/store'

export default [{
        path: '',
        name: 'home',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/Index')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/Index')
    },
	{
        path: '/admin',
        name: 'admin',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/admin/Admin.vue')
    },
    {
        path: '/payments',
        name: 'payments',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/Payments')
    },
    {
        path: '/identity',
        name: 'identity',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/Identify')
    },
    {
        path: '/membership',
        name: 'membership',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/Membership')
    },
	{
		path: '/help',
		name: 'help',
		meta: {
			authRequired: true,
		},
		component: () => import('../views/pages/profile/Help')
	},
    {
        path: '/chat',
        name: 'chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/checkout',
        name: 'checkout',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/checkout/checkout.vue')
    },
	{
        path: '/payment/finalize/stripe/:params',
        name: 'securePaymentReturn',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/checkout/securePaymentReturn.vue')
    },
    {
        path: '/complete',
        name: 'checkout-complete',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/checkout/complete.vue')
    },
    {
        path: '/error',
        name: 'checkout-error',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/checkout/error.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/magic-link',
        name: 'magic-link',
        component: () => import('../views/pages/account/magic-link'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
		path: '/magic-link/:token',
        name: 'magic-login',
        component: () => import('../views/pages/account/magic-link-login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({
                        name: 'home'
                    })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
	{
		path: '/auth/reset-password/:token',
		name: 'Reset-password',
		component: () => import('../views/pages/account/new-password'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					next({
						name: 'home'
					})
				} else {
					// Continue to the login page
					next()
				}
			},
		},
	},
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? {
                    name: 'home'
                } : {
                    ...routeFrom
                })
            },
        },
    },
    {
        path: '/lock-screen',
        name: 'lock-screen',
        component: () => import('../views/pages/account/lock-screen'),
    },
    {
        path: '/contact-us',
        name: 'contactus',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/static/contactUs')
    },
    {
        path: '/terms',
        name: 'terms',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/static/terms')
    },
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			authRequired: true,
		},
		component: () => import('../views/pages/static/privacy')
	},
    {
        path: '/faq',
        name: 'faq',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/static/faq'),
    },
	{
		path: '/register/success/:email/:username',
		name: 'register-success',
		meta: {
			authRequired: false,
		},
		component: () => import('../views/pages/account/register-success.vue'),
	},
	{
		path: '/email-verification',
		name: 'email-verification',
		meta: {
			authRequired: false,
		},
		component: () => import('../views/pages/account/email-verification-form.vue'),
	},
	{
        path: '*',
        name: 'Not Found',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/static/404.vue')
    },
]