import axios from 'axios';

const getAuthToken = () => 'Bearer ' + localStorage.getItem('token');
const removeAuthToken = () => localStorage.removeItem('token');
const removeUser = () => localStorage.removeItem('user');

const httpClient = axios.create({
	timeout: 50000,
});

const authInterceptor = (config) => {
	config.headers['Authorization'] = getAuthToken();
	return config;
};

httpClient.interceptors.request.use(authInterceptor);
// httpClient.interceptors.response.use(
// 	function (response) {
// 		return response;
// 	},
// 	function (error) {
// 		console.log(error.response.status);
// 		if (error.response.status === 401) {
// 			removeAuthToken();
// 			removeUser();
// 		}
// 		console.log(error.response);
// 	}
// );
httpClient.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.status === 401) {
			removeAuthToken();
			removeUser();
		} else {
			return Promise.reject(error.response);
		}
	}
);
export default httpClient;
