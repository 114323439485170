import {apiUrl} from "@/state/helpers"
import httpClient from "@/helpers/httpClient";

export const state = {
	paymentCardList: [],
	selectedPaymentCard: null,
};

export const mutations = {
	async UPDATE_PAYMENT_CARD_LIST(state, payload) {
		state.paymentCardList = payload;
		if (state.paymentCardList) {
			const defaultItem = state.paymentCardList.find(item => item.is_default);
			state.selectedPaymentCard = defaultItem || state.paymentCardList[0];
		}
	},
	async SET_SELECTED_PAYMENT_CARD(state, payload) {
		state.selectedPaymentCard = payload;
	}
};
export const actions = {
	async getPaymentCardsList({commit}) {
		try {
			await httpClient.get(apiUrl + '/api/v1/cards')
				.then((response) => {
					commit('UPDATE_PAYMENT_CARD_LIST', response.data.cards)
				})
		} catch (e) {
			console.log(e);
		}
	},
	async addCreditCard ({dispatch}, payload) {
		try {
			dispatch('notification/clear', '', {root: true})
			await httpClient.post(apiUrl + '/api/v1/cards', payload)
				.then((response) => {
					if(response.status === 200) {
						dispatch('getPaymentCardsList')
					}
				})
		} catch(e) {
			console.log(e)
			if(e.data.errors.exp_month || e.data.errors.exp_year || e.data.errors.card_number) {
				dispatch('notification/error', 'Expired date or card number is invalid', {root: true});
			} else if(e.data.errors.cvv) {
				dispatch('notification/error', 'CVV is invalid', {root: true});
			}
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 10000)
		}
	},
	async updateCreditCard({dispatch}, payload) {
		try {
			await httpClient.patch(apiUrl + `/api/v1/cards/set-default/${payload}`)
				.then((response) => {
					if(response.status === 200) {
						dispatch('getPaymentCardsList')
					}
				})
		} catch(e) {
			dispatch('notification/error', 'Wrong card data', {root: true});
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 2000)
		}
	},
	async deleteCreditCard({dispatch},payload) {
		try {
			await httpClient.delete(apiUrl + `/api/v1/cards/${payload}`)
				.then(() => {
				dispatch('getPaymentCardsList')
			})
		} catch(e) {
			console.log(e)
		}
	},
	async setSelectedPaymentCard({commit}, payload) {
		commit('SET_SELECTED_PAYMENT_CARD', payload)
	}
};
export const getters = {};