// import {getFirebaseBackend} from '../../authUtils.js'
import axios from "axios";
import router from "../../router/index";
import {apiUrl} from "@/state/helpers";

export const state = {
	currentUser: sessionStorage.getItem('authUser'),
	authError: '',
	authLoading: false,
}

export const mutations = {
	SET_CURRENT_USER(state, newValue) {
		state.currentUser = newValue
		saveState('auth.currentUser', newValue)
	},
	UPDATE_AUTH_ERROR(state, error) {
		state.authError = error;
	},
	SET_LOADING(state, data) {
		state.authLoading = data
	},
}

export const getters = {
	// Whether the user is currently logged in.
	loggedIn(state) {
		return !!state.currentUser
	},

	authError(state) {
		return state.authError;
	},
}

export const actions = {
	// This is automatically run in `src/state/store.js` when the app
	// starts, along with any other actions named `init` in other modules.
	// eslint-disable-next-line no-unused-vars
	init({state, dispatch}) {
		dispatch('validate')
	},

	// Logs in the current user.
	async logIn({commit, dispatch, getters}, {email, password} = {}) {
		if (getters.loggedIn) return dispatch('validate');

		try {
			await axios.post(apiUrl + '/api/v1/auth/login', {email, password})
				.then((response) => {
					const token = response.data.access_token;
					const user = response.data.user;
					localStorage.setItem('token', token)
					axios.defaults.headers.common['Authorization'] = token
					commit('SET_CURRENT_USER', user)
					dispatch('notification/success', 'Login successful', {root: true});
					setTimeout(() => {
						dispatch('notification/clear', '', {root: true});
						window.location.href = '/chat'
					}, 3000)
				})
		} catch (e) {
			console.log(e);
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			dispatch('notification/error', 'Unauthorized', {root: true});
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true});
			}, 5000)
		}

	},

	// Logs out the current user.
	async logOut({commit, dispatch}) {
		try{
			await axios.post(apiUrl + '/api/v1/auth/logout', {}, {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('token')
				}
			}).then(() => {
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				commit('SET_CURRENT_USER', null);
				dispatch('notification/success', 'Logout successful', {root: true})
					.then(() => {
						router.push('/login');
						setTimeout(() => {
							dispatch('notification/clear', 'Logout successful', {root: true});
						}, 5000)
					})
			})
				.catch((error) => {
					console.log(this._handleError(error));
				})
		} catch(e) {
			console.log(e)
		}
	},

	// register the user
	async register({commit, dispatch, getters}, user) {
		if (getters.loggedIn) return dispatch('validate')

		try{
			await axios.post(apiUrl + `/api/v1/auth/register`, user)
				.then(() => {
					// const token = response.data.access_token;
					// const user = response.data.user;
					// localStorage.setItem('token', token)
					// axios.defaults.headers.common['Authorization'] = token
					// commit('SET_CURRENT_USER', user)
					dispatch('notification/success', 'Registration successful. Please verify your email', {root: true})
						.then(() => {
							setTimeout(() => {
								dispatch('notification/clear', '', {root: true});
								router.push({ name: 'register-success', params: { email: user.email, username: user.first_name } });
							}, 3000)
						})
				})
		} catch(e) {
			if(e.response.data.errors.first_name) {
				dispatch('notification/error', e.response.data.errors.first_name[0], {root: true});
			} else if(e.response.data.errors.password) {
				const pwdResponse = e.response.data.errors.password.join("\r\n")
				dispatch('notification/error', pwdResponse, {root: true});
			} else if(e.response.data.errors.email) {
				dispatch('notification/error', e.response.data.errors.email[0], {root: true});
			} else {
				dispatch('notification/error', 'Wrong Request', {root: true});
			}
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 9000)
		}
	},

	async sendVerificationEmail({dispatch}, email) {
		try{
			await axios.post(apiUrl + `/api/v1/email/verify`, email)
				.then(() => {
					dispatch('notification/success', 'Send successfully. Please check your email', {root: true})
				})
		} catch(e) {
			if(e.response.data.msg) {
				dispatch('notification/error', e.response.data.msg, {root: true});
			} else if(e.response.data.errors.email) {
				dispatch('notification/error', e.response.data.errors.email[0], {root: true});
			} else {
				dispatch('notification/error', 'Wrong Request', {root: true});
			}
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 9000)
		}
	},

	async sendMagicLink({dispatch}, email) {
		try{
			await axios.post(apiUrl + `/api/v1/request-magic-link`, email)
				.then(() => {
					dispatch('notification/success', 'Magic link was sent successfully. Please check your email', {root: true})
				})
		} catch(e) {
			if(e.response.data.message) {
				dispatch('notification/error', e.response.data.message, {root: true});
			} else if(e.response.data.msg) {
				dispatch('notification/error', e.response.data.msg, {root: true});
			} else if(e.response.data.errors.email) {
				dispatch('notification/error', e.response.data.errors.email[0], {root: true});
			} else {
				dispatch('notification/error', 'Wrong Request', {root: true});
			}
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 3000)
		}
	},

	async loginWithMagicLink({commit, dispatch}, link) {
		try{
			commit("SET_LOADING", true);
			await axios.get(apiUrl + `/api/v1/login${link}`)
				.then((response) => {
					const token = response.data.access_token;
					const user = response.data.user;
					localStorage.setItem('token', token)
					axios.defaults.headers.common['Authorization'] = token
					commit('SET_CURRENT_USER', user)
					dispatch('notification/success', 'Login successful', {root: true});
					commit("SET_LOADING", false);
					setTimeout(() => {
						dispatch('notification/clear', '', {root: true});
						window.location.href = '/chat'
					}, 2000)
				})
		} catch(e) {
			console.log(e)
			dispatch('notification/error', 'Invalid or expired token', {root: true});
			commit("SET_LOADING", false);
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 3000);
			commit("SET_LOADING", false);
		}
	},

	// eslint-disable-next-line no-unused-vars
	async resetPassword({commit, dispatch, getters}, {email} = {}) {
		if (getters.loggedIn) return dispatch('validate')
			try {
				await axios.post(apiUrl + '/api/v1/password/reset-request', {email})
					.then((response) => {
						if(response.status === 200){
							dispatch('notification/success', 'We have emailed your password reset link!', {root: true});
						}
					})
			} catch(e){
				dispatch('notification/error', e.response.data.message, {root: true});
			} finally {
				setTimeout(() => {
					dispatch('notification/clear', '', {root: true})
				}, 5000)
			}
	},

	async handleResetPassword({dispatch},resetData) {
		try{
			await axios.post(apiUrl + '/api/v1/password/new-password', resetData)
				.then((response) => {
					if(response.status === 200){
						dispatch('notification/success', 'Password Successfully changed!', {root: true});
						window.location.href = '/login'
					}
				})
		} catch(e){
			if(e.response?.data.message.password) {
				const errors = e.response.data.message.password;
				errors.forEach((err) => {
					dispatch('notification/error', err, {root: true});
				});
			} else {
				dispatch('notification/error', e.response?.data.message, {root: true});
			}
		} finally {
			setTimeout(() => {
				dispatch('notification/clear', '', {root: true})
			}, 5000)
		}
	},
	// Validates the current user's token and refreshes it
	// with new data from the API.
	// eslint-disable-next-line no-unused-vars
	validate({commit, state}) {
		if (!state.currentUser) return Promise.resolve(null)
		const user = localStorage.getItem('auth.currentUser');
		commit('SET_CURRENT_USER', user)
		return user;
	},
}

// ===
// Private helpers
// ===

function saveState(key, state) {
	window.localStorage.setItem(key, JSON.stringify(state))
}
